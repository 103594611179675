function whichDevice() {
  if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
    return "smartPhone";
  } else if (navigator.userAgent.match(/iPad|(?!(Android.*Mobile)+)Android/)) {
    return "tablet";
  } else {
    return "PC";
  }
}

if (whichDevice() === "tablet") {
  $("[name='viewport']").attr("content", "width=1200");
}

// header sp
const headerTrigger = document.getElementById("header-trigger");
headerTrigger.addEventListener("click", headerOpen, false);

function headerOpen() {
  const spMenu = document.getElementById("sp-menu");

  headerTrigger.classList.toggle("is-triggered");
  spMenu.classList.toggle("is-show");
}

$(function () {
  // smooth scroll
  $("a[href^='#']").click(function () {
    var speed = 400;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? "html" : href);
    var position = target.offset().top;
    $("html, body").animate({ scrollTop: position }, speed, "swing");
    return false;
  });
});
